.sidebar-container {
    width: 250px !important;
    padding: 20px;
}

@media (max-width: 900px) {
    .sidebar-container {
        width: 250px !important;
    }
}

@media (min-width: 900px) {
    .sidebar-container {
        width: 350px !important;
    }
}
.css-1lca1zc,.css-1wvake5 {
    padding: 0 !important;
}
